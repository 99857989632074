import 'nodelist-foreach'
import DomComponent from 'abstractions/DomComponent'

import Submenu from 'components/Submenu'
import isMobile from 'utils/is-mobile'

export default class Menu extends DomComponent {
  didMount () {
    this.refs.submenus = []
    this.refs.mobileCheck = document.getElementById('openMobileMenu')
    this.refs.base.querySelectorAll('[data-open]').forEach(link => {
      const submenu = new Submenu({ link, beforeOpen: this.close.bind(this) })
      submenu.hydrate(link)
      this.refs.submenus.push(submenu)
    })

    // Allow openning mobile menu by clicking on the home cover (this improves
    // landing UX by a lot)
    const homeCover = document.querySelector('.home__cover')
    if (isMobile() && homeCover) {
      homeCover.addEventListener('click', e => {
        this.refs.mobileCheck.checked = true
      })
    }
  }

  open () {
    this.refs.submenus.forEach(submenu => submenu.open())
  }

  close () {
    this.refs.submenus.forEach(submenu => submenu.close())

    if (isMobile()) this.refs.mobileCheck.checked = false
  }
}
