import 'nodelist-foreach'
import lozad from 'lozad'
import anime from 'animejs/lib/anime.es.js'
import DomComponent from 'abstractions/DomComponent'
import Photoswipe from 'components/Photoswipe'

export default class Page extends DomComponent {
  didInit () {
    this.animate = this.animate.bind(this)
  }

  didMount () {
    this.refs.cover = this.refs.base.querySelector('.page__cover img')
    this.refs.title = this.refs.base.querySelector('.page__title')

    this.refs.figures = this.refs.base.querySelectorAll('.page__text figure.image')
    this.refs.photoswipe = new Photoswipe(this.refs)
    this.refs.photoswipe.hydrate(document.querySelector('.pswp'))

    this.refs.lazyload = lozad(this.refs.base.querySelectorAll('.page__text figure.image img'))
    this.refs.lazyload.observe()
  }

  async waitUntilLoaded () {
    if (!this.refs.cover) return Promise.resolve()
    if (this.refs.cover.complete && this.refs.cover.naturalHeight !== 0) return Promise.resolve()
    return new Promise(resolve => {
      this.refs.cover.onload = resolve
    })
  }

  prepareAnimation () {
    if (!this.refs.title) return
    this.refs.title.style.opacity = 0
  }

  animate () {
    if (!this.refs.title) return
    anime({
      targets: this.refs.title,
      opacity: [0, 1],
      scale: [1.02, 1],
      duration: 600,
      easing: 'cubicBezier(.215,.61,.355,1)'
    })
  }
}
