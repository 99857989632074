import 'nodelist-foreach'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'
import DomComponent from 'abstractions/DomComponent'

const OPTIONS = {
  bgOpacity: 1,
  history: false,
  allowPanToNext: false,
  barsSize: { top: 0, bottom: 'auto' },
  captionEl: true,
  fullscreenEl: false,
  zoomEl: false,
  shareEl: false,
  counterEl: true,
  preloaderEl: true,
  showHideOpacity: true,
  getThumbBoundsFn: function (index) {
    const slide = this.state.slides[index]
    if (!slide) return

    const pageYScroll = window.pageYOffset || document.documentElement.scrollTop
    const { left, top, width } = slide.element.getBoundingClientRect()
    return {
      x: left,
      y: top + pageYScroll,
      w: width
    }
  }
}

export default class Photoswipe extends DomComponent {
  didInit ({ figures }) {
    this.refs.photoswipe = undefined
    this.state.slides = []
    this.refs.figures = figures
  }

  didMount () {
    if (!this.refs.figures) return

    this.refs.figures.forEach((figure, index) => {
      const img = figure.querySelector('img')
      if (!img) return

      figure.classList.add('has-photoswipe')
      const figcaption = figure.querySelector('figcaption')

      this.state.slides.push({
        index,
        element: img,
        title: figcaption && figcaption.innerHTML,
        src: img.getAttribute('data-zoom-src'),
        w: img.getAttribute('data-width'),
        h: img.getAttribute('data-height')
      })

      figure.addEventListener('click', e => {
        e.preventDefault()
        this.open(index)
      })
    })
  }

  open (index = 0) {
    this.refs.photoswipe = new PhotoSwipe(
      this.refs.base,
      PhotoSwipeUIDefault,
      this.state.slides,
      Object.assign({}, OPTIONS, {
        index,
        getThumbBoundsFn: OPTIONS.getThumbBoundsFn.bind(this)
      })
    )
    this.refs.photoswipe.init()
  }

  willUnmount () {
    try {
      this.refs.photoswipe.destroy()
    } catch (e) {} finally {
      this.refs.photoswipe = undefined
    }
  }
}
