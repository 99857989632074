import 'nodelist-foreach'
import DomComponent from 'abstractions/DomComponent'
import noop from 'utils/noop'

export default class Submenu extends DomComponent {
  didInit ({ link, beforeOpen = noop, beforeClose = noop }) {
    this.handleClick = this.handleClick.bind(this)

    this.state = {
      target: link.getAttribute('data-open'),
      open: false
    }

    this.beforeOpen = beforeOpen
    this.beforeClose = beforeClose

    this.refs.link = link
    this.refs.menu = document.querySelector(`[data-menu=${this.state.target}]`)
  }

  didMount () {
    // Set internal state to open/close based on URL
    window.requestAnimationFrame(() => {
      this.state.open = new RegExp(`${this.state.target}/?$`, 'i').test(window.location.href)
      this.refs.link.classList[this.state.open ? 'add' : 'remove']('is-active')
      this.refs.menu.classList[this.state.open ? 'add' : 'remove']('is-open')
    }, 500)

    this.refs.link.classList.add('no-barba')
    this.refs.link.addEventListener('click', this.handleClick)
  }

  handleClick (e) {
    e.preventDefault()
    this.toggle()
  }

  toggle () {
    this.state.open ? this.close() : this.open()
  }

  open () {
    if (this.state.open) return
    this.beforeOpen()
    this.state.open = true

    this.refs.link.classList.add('is-active')
    this.refs.menu.classList.add('is-open')
  }

  close () {
    if (!this.state.open) return
    this.beforeClose()
    this.state.open = false

    this.refs.link.classList.remove('is-active')
    this.refs.menu.classList.remove('is-open')
  }

  willUnmount () {
    this.refs.link.removeEventListener('click', this.handleClick)
  }
}
