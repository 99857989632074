import barba from '@barba/core'

import Menu from 'components/Menu'
import Page from 'components/Page'

const components = {
  menu: new Menu(),
  page: new Page()
}

document.querySelector('[data-barba=container]') && barba.init({
  debug: !window.ENV.production,
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  views: [{
    namespace: 'page',

    beforeEnter: () => {
      window.scrollTo(0, 0)
      components.menu.hydrate(document.querySelector('.menu'))
    },

    afterEnter: async ({ next }) => {
      if (components.page) components.page.destroy({ removeBase: false })
      components.page = new Page()
      components.page.hydrate(next.container)

      components.page.prepareAnimation()
      await components.page.waitUntilLoaded()

      components.menu.close()
      components.page.animate()
    },

    beforeLeave: ({ trigger }) => {
      if (trigger && trigger.blur) trigger.blur()
      components.page.destroy({ removeBase: false })
      components.page = undefined
    }
  }]
})
